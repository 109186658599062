<ng-container>
  <c-container [fluid]="true" class="border-bottom px-4">
    <button [cSidebarToggle]="sidebarId" cHeaderToggler class="btn" toggle="visible"
      style="margin-inline-start: -14px;">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-header-nav class="d-none d-md-flex">
      <c-nav-item>
        <!-- <a cNavLink routerLink="/dashboard" routerLinkActive="active">Dashboard</a> -->
        <h4 class="mb-0">{{routeName}}</h4> 
      </c-nav-item>
    </c-header-nav>

    <c-header-nav class="d-none d-md-flex ms-auto">
      <c-dropdown class="mx-2">
        <button cButton cDropdownToggle class="env-button small text-capitalize" 
        [style.background]="colorMode() == 'dark' ? '#212631' : '#dee2e6'">
        {{selectedProject}} 
        </button>
        <ul cDropdownMenu>
          <li ><a [routerLink]="['/project']" cDropdownItem> + New Project</a></li>
           <li *ngFor="let list of listOfProjects" [class.active]="isSelectedProject(list)" (click)="getSelectedProject(list)"><a [routerLink]="[]"
              cDropdownItem>{{list?.name}}</a></li> 
        </ul>
      </c-dropdown>

      <c-dropdown>
        <button cButton cDropdownToggle class="env-button small text-capitalize" 
        *ngIf="!!selectedEnvironment" [style.background]="colorMode() == 'dark' ? '#212631' : '#dee2e6'">
          {{selectedEnvironment}} 
        </button>
        <ul cDropdownMenu>
          <li ><a [routerLink]="['/environment']" cDropdownItem> + New Environment</a></li>
          <li *ngFor="let list of listOfEnviroinments" [class.active]="isSelectedEnv(list)" (click)="getSelectedEnv(list)"><a [routerLink]="[]"
              cDropdownItem>{{list?.name}}</a></li>
        </ul>
      </c-dropdown>
    </c-header-nav>

    <c-header-nav class="ms-auto ms-md-0">
      <div class="nav-item py-1">
        <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
      </div>
      <ng-container *ngTemplateOutlet="themeDropdown" />
      <div class="nav-item py-1">
        <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
      </div>
    </c-header-nav>

    <c-header-nav class="mx-0">
      <ng-container *ngTemplateOutlet="userDropdown" />
    </c-header-nav>

  </c-container>
</ng-container>

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item">
    <button [caret]="false" cDropdownToggle class="py-0 pe-0">
      <c-avatar shape="rounded-1" [size]="'s'" src="./assets/images/avatars/avatar.jpg" 
        textColor="primary" />
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <li></li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="" (click)="logout()">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle>
      <svg cIcon [name]="icons()" size="lg"></svg>
    </button>
    <div cDropdownMenu>
      @for (mode of colorModes; track mode.name) {
      <button (click)="setTheme(mode.name)" [active]="colorMode()===mode.name" [routerLink]="[]" cDropdownItem
        class="d-flex align-items-center">
        <svg cIcon class="me-2" [name]="mode.icon" size="lg"></svg>
        {{ mode.text }}
      </button>
      }
    </div>
  </c-dropdown>
</ng-template>